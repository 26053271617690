import { Translate, TranslatePlural } from '@change/core/react/i18n';
import { VisuallyHidden } from '@change/design-system/a11y';
import { Button } from '@change/design-system/components/actions';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Box, Container } from '@change/design-system/layout';

import { useLazyLoadedListFocus } from 'src/app/shared/hooks/focus';

import { BasicCard } from './components/BasicCard';
import { ExpandedCard } from './components/ExpandedCard';
import { FeaturedCard } from './components/FeaturedCard';
import { usePageBody } from './hooks/usePageBody';

export function PageBody() {
	const {
		data: { hasNextPage, items, nextPageLoading, guides },
		actions: { loadMore },
	} = usePageBody();
	const { containerRef, loadMoreRef, loadingRef, getListItemRef, recordListEvent, lastEvent } =
		useLazyLoadedListFocus();

	const i18nKeyPrefix = `corgi.${guides ? 'guides' : 'news'}`;

	return (
		<Container variant="small" ref={containerRef} mb={16}>
			<Box as="ul">
				{items.map((item, index) => {
					const fullWidth = index === 0 || index === 7;
					return (
						<Box
							as="li"
							key={item.id}
							sx={{
								display: 'inline-block',
								width: fullWidth ? '100%' : ['100%', 'calc(33.33% - 16px)'],
								verticalAlign: 'top',
							}}
							mx={[0, fullWidth ? 0 : 8]}
							px={[fullWidth ? 0 : 8, 0]}
							mb={[30, 60]}
						>
							{lastEvent.type === 'loadMore' && index === lastEvent.firstNewItemIndex && (
								<VisuallyHidden role="alert">
									<TranslatePlural
										value={`${i18nKeyPrefix}.list.fetched_more`}
										count={items.length - lastEvent.previousItemsCount}
										replacements={{ count: items.length - lastEvent.previousItemsCount }}
									/>
								</VisuallyHidden>
							)}
							{(() => {
								if (index === 0) return <FeaturedCard item={item} ref={getListItemRef(index)} />;
								if (index === 7) return <ExpandedCard item={item} ref={getListItemRef(index)} />;
								return <BasicCard item={item} ref={getListItemRef(index)} />;
							})()}
						</Box>
					);
				})}
			</Box>
			{nextPageLoading && (
				<>
					<VisuallyHidden role="alert">
						<Translate value={`${i18nKeyPrefix}.list.fetching_more`} />
					</VisuallyHidden>
					{/* 44 is the height of the button */}
					<Loader size={44} mx="auto" ref={loadingRef} mt={24} />
				</>
			)}
			{hasNextPage && !nextPageLoading && (
				<Box px={8}>
					<Button
						variant="secondary"
						sx={{ width: '100%' }}
						onClick={() => {
							recordListEvent({ type: 'loadMore', itemsCount: items.length });
							loadMore();
						}}
						ref={loadMoreRef}
						mt={24}
					>
						<Translate value="fe.components.load_more.load_more" />
					</Button>
				</Box>
			)}
		</Container>
	);
}
