import type { JSX } from 'react';

import { PageBody } from '../components/PageBody';
import { PageHeading } from '../components/PageHeading';
import { PageMeta } from '../components/PageMeta';

import { useTrackPageView } from './hooks/useTrackPageView';

export function Layout(): JSX.Element {
	useTrackPageView();

	return (
		<>
			<PageMeta />
			<PageHeading />
			<PageBody />
		</>
	);
}
