import type { PrefetchContext, PrefetchedDataProps } from '@change/core/react/prefetch';

import { useLocale } from 'src/app/shared/hooks/l10n';
import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchNewsAndGuidesListPageDataFromApi, useNewsAndGuidesListPageDataFromApi } from './pageData';
import {
	prefetchNewAndGuidesListPaginatedDataFromApi,
	useNewAndGuidesListPaginatedDataFromApi,
} from './pagePaginatedData';

export type NewsAndGuidesListPagePrefetchedContext = Readonly<{
	pageData: Parameters<typeof useNewsAndGuidesListPageDataFromApi>[0];
	pagePaginatedData: Parameters<typeof useNewAndGuidesListPaginatedDataFromApi>[0];
}>;

export type NewsAndGuidesListPageCombinedState = MergedState<
	ReturnType<typeof useNewsAndGuidesListPageDataFromApi>,
	ReturnType<typeof useNewAndGuidesListPaginatedDataFromApi>
>;

export function usePageContext(
	countryCode: string,
	guides: boolean,
	prefetchedData?: NewsAndGuidesListPagePrefetchedContext,
): NewsAndGuidesListPageCombinedState {
	const locale = useLocale();

	return useMergedStates(
		useNewsAndGuidesListPageDataFromApi(prefetchedData?.pageData, { countryCode, locale, guides }),
		useNewAndGuidesListPaginatedDataFromApi(prefetchedData?.pagePaginatedData, { countryCode, locale, guides }),
	);
}

export async function prefetchPageContext(
	countryCode: string,
	guides: boolean,
	context: PrefetchContext,
): Promise<NewsAndGuidesListPagePrefetchedContext> {
	const { locale } = context.l10n;

	const [pageData, pagePaginatedData] = await Promise.all([
		prefetchNewsAndGuidesListPageDataFromApi(context, { countryCode, locale, guides }),
		prefetchNewAndGuidesListPaginatedDataFromApi(context, { countryCode, locale, guides }),
	]);

	return { pageData, pagePaginatedData };
}

export type NewsAndGuidesListPagePrefetchedDataProps = PrefetchedDataProps<NewsAndGuidesListPagePrefetchedContext>;
