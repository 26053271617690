import { useNewsAndGuidesListPageData } from 'src/app/pages/newsAndGuides/list/pageContext';

type Result = ModelHookResult<{
	guides: boolean;
	showGuidesLink: boolean;
	urlBase: string;
}>;

export function usePageHeading(): Result {
	const {
		data: { hasGuides, countryCode, guides },
	} = useNewsAndGuidesListPageData();

	return {
		data: {
			guides,
			showGuidesLink: hasGuides,
			urlBase: `/l/${encodeURIComponent(countryCode)}`,
		},
	};
}
