import type { ForwardedRef } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Link } from '@change/core/react/router';
import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import type { NewsAndGuidesListItem } from 'src/app/pages/newsAndGuides/list/pageContext';
import { useItemCard } from 'src/app/pages/newsAndGuides/list/shared/hooks';

type Props = {
	item: NewsAndGuidesListItem;
};

export function ExpandedCardInner({ item }: Props, ref: ForwardedRef<HTMLAnchorElement>) {
	const {
		data: { categoryName, targetUrl, photoUrl },
	} = useItemCard(item);

	return (
		<Link
			to={targetUrl}
			sx={{
				...linkResetStyles,
				position: 'relative',
				display: ['block', 'flex'],
				justifyContent: 'flex-end',
				alignItems: 'flex-start',
				width: '100%',
				height: ['auto', 264],
				pt: [0, 16, 24],
			}}
			ref={ref}
		>
			<Box
				sx={{
					display: 'block',
					position: ['initial', 'absolute'],
					top: 0,
					left: 0,
					width: ['100%', '60%', '50%'],
					height: [220, '100%'],
					backgroundPosition: '50%',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
				// using style here is done on purpose for optimization purposes
				// eslint-disable-next-line react/forbid-component-props
				style={{
					// TODO use a default image unrelated to petitions?
					backgroundImage: `url("${photoUrl || 'https://static.change.org/images/default-petition.gif'}")`,
				}}
				role="presentation"
			/>
			<Box
				sx={{
					position: ['initial', 'relative'],
					width: ['100%', '58%'],
					backgroundColor: 'white',
				}}
				py={24}
				pl={[8, 24]}
				pr={[8, 114]}
			>
				<Text as="div" color="neutral-grey700" size="caption" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
					{categoryName}
				</Text>
				<Text as="div" size="large" breakWord sx={{ fontWeight: 'bold', fontSize: 20 }} my={8}>
					{item.title}
				</Text>
				<Html as="div" html={item.excerpt} breakWord lineClamp={[null, 3]} />
			</Box>
		</Link>
	);
}

export const ExpandedCard = forwardRef(ExpandedCardInner);
