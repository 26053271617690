import type { ComponentType, JSX, PropsWithChildren } from 'react';

import { TrackingContext } from '@change/core/react/tracking';

import type { ErrorStateDetails } from 'src/app/shared/utils/async';
import { isError, isLoaded } from 'src/app/shared/utils/async';

import type { NewsAndGuidesListPageCombinedState, NewsAndGuidesListPagePrefetchedContext } from './hook';
import { usePageContext } from './hook';
import { NewsAndGuidesListPageDataContextProvider } from './pageData';
import { NewAndGuidesListPaginatedDataContextProvider } from './pagePaginatedData';

export type ErrorHandlerProps = { context: ErrorStateDetails<NewsAndGuidesListPageCombinedState> };

type Props = {
	countryCode: string;
	guides: boolean;
	prefetchedData: NewsAndGuidesListPagePrefetchedContext | undefined;
	errorHandler: ComponentType<ErrorHandlerProps>;
	loadingFallback: JSX.Element;
};

export function NewsAndGuidesListPageContextProvider({
	children,
	countryCode,
	guides,
	prefetchedData,
	errorHandler: ErrorHandler,
	loadingFallback,
}: PropsWithChildren<Props>): JSX.Element | null {
	const pageContextState = usePageContext(countryCode, guides, prefetchedData);

	if (isError(pageContextState)) {
		const { status, ...rest } = pageContextState;
		return <ErrorHandler context={rest} />;
	}
	if (!isLoaded(pageContextState)) return loadingFallback;

	const { pageData, pagePaginatedData } = pageContextState;

	return (
		<NewsAndGuidesListPageDataContextProvider pageData={pageData}>
			<NewAndGuidesListPaginatedDataContextProvider pagePaginatedData={pagePaginatedData}>
				<TrackingContext properties={{ country_code: countryCode }}>{children}</TrackingContext>
			</NewAndGuidesListPaginatedDataContextProvider>
		</NewsAndGuidesListPageDataContextProvider>
	);
}
