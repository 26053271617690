import type { JSX } from 'react';

import { Redirect, useKnownAvailableParams } from '@change/core/react/router';

import type { ErrorHandlerProps } from 'src/app/pages/newsAndGuides/list/pageContext';

export function NewsAndGuidesListErrorHandler({ context: { error } }: ErrorHandlerProps): JSX.Element {
	const { countryCode } = useKnownAvailableParams<{ countryCode: string }>();

	if (error === 'news_and_guides_list_page_data_no_guides') {
		/* using forceMode=external to avoid loading indicator when redirecting client-side
		   this would b unnecessary if those pages had different paths */
		return <Redirect to={`/l/${countryCode}`} forceMode="external" />;
	}
	return <>{`Failed to retrieve data for NewsAndGuidesList page (error: ${error})`}</>;
}
