import { createPrefetchableAsyncPaginatedDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getFirstPageData, getNextPageData } from './api';
import type { UserProfilePageSignedPetitionsDataErrorCode } from './types';

export const {
	Context: NewAndGuidesListPaginatedDataContext,
	Provider: NewAndGuidesListPaginatedDataContextProvider,
	useContext: useNewAndGuidesListPaginatedData,
	useAsyncData: useNewAndGuidesListPaginatedDataFromApi,
	prefetchAsyncData: prefetchNewAndGuidesListPaginatedDataFromApi,
} = createPrefetchableAsyncPaginatedDataContext({
	name: 'NewAndGuidesListPaginatedDataContext',
	dataProperty: 'pagePaginatedData',
	getFirstPageData,
	getNextPageData,
	hookDeps: ({ countryCode, locale, guides }) => [countryCode, locale, guides],
	getUniqueId: ({ countryCode, locale, guides }) => [countryCode, locale, guides].join('|'),
	errorHandler: pageDataErrorHandler<UserProfilePageSignedPetitionsDataErrorCode>(),
});
