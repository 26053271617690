import type { ForwardedRef } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Link } from '@change/core/react/router';
import { Box } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import type { NewsAndGuidesListItem } from 'src/app/pages/newsAndGuides/list/pageContext';
import { useItemCard } from 'src/app/pages/newsAndGuides/list/shared/hooks';

type Props = {
	item: NewsAndGuidesListItem;
};

export function BasicCardInner({ item }: Props, ref: ForwardedRef<HTMLAnchorElement>) {
	const {
		data: { categoryName, targetUrl, smallPhotoUrl },
	} = useItemCard(item);

	return (
		<Link
			to={targetUrl}
			sx={{
				...linkResetStyles,
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: 225,
				backgroundColor: !smallPhotoUrl && 'neutral-grey50',
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: 'neutral-grey50',
				px: 16,
				py: 40,
			}}
			ref={ref}
		>
			{smallPhotoUrl && (
				<Box
					sx={{
						display: 'block',
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundPosition: '50%',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						opacity: '.1',
					}}
					// using style here is done on purpose for optimization purposes
					// eslint-disable-next-line react/forbid-component-props
					style={{
						backgroundImage: `url("${smallPhotoUrl}")`,
					}}
					role="presentation"
				/>
			)}
			<Text color="neutral-grey700" size="caption" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
				{categoryName}
			</Text>
			<Text size="large" breakWord lineClamp={3} sx={{ flex: 1, fontWeight: 'bold', fontSize: 20 }} my={8}>
				{item.title}
			</Text>
			<Text color="neutral-grey700" size="caption">
				{item.createdAtLocalized}
			</Text>
		</Link>
	);
}

export const BasicCard = forwardRef(BasicCardInner);
