import {
	useNewAndGuidesListPaginatedData,
	useNewsAndGuidesListPageData,
} from 'src/app/pages/newsAndGuides/list/pageContext';
import type { NewsAndGuidesListItem } from 'src/app/pages/newsAndGuides/list/pageContext';

type Result = ModelHookResult<
	{
		hasNextPage: boolean;
		items: readonly NewsAndGuidesListItem[];
		nextPageLoading: boolean;
		guides: boolean;
	},
	{
		loadMore: () => void;
	}
>;

export function usePageBody(): Result {
	const {
		data: { hasNextPage, items, nextPageLoading },
		actions: { loadMore },
	} = useNewAndGuidesListPaginatedData();
	const {
		data: { guides },
	} = useNewsAndGuidesListPageData();

	return {
		data: {
			items,
			hasNextPage,
			nextPageLoading,
			guides,
		},

		actions: {
			loadMore,
		},
	};
}
