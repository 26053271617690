import type { UtilityContext } from '@change/core/react/utilityContext';

import { NotFoundError } from 'src/shared/error';

import type { PaginatedData } from 'src/app/shared/contexts/asyncData';

import type {
	GuidesListPaginatedDataQuery,
	GuidesListPaginatedDataQueryVariables,
	NewsListPaginatedDataQuery,
	NewsListPaginatedDataQueryVariables,
} from './api.graphql';
import { GuidesListPaginatedData, NewsListPaginatedData } from './api.graphql';

type Args = { countryCode: string; locale: string; guides: boolean };

export type NewsAndGuidesListItem =
	| NonNullable<NewsListPaginatedDataQuery['connection']>['nodes'][0]
	| NonNullable<GuidesListPaginatedDataQuery['connection']>['nodes'][0];

export type NewsAndGuidesListPagePaginatedData = PaginatedData<NewsAndGuidesListItem>;

async function getData(
	{ gql: { fetch } }: UtilityContext,
	{ countryCode, locale, guides }: Args,
	count: number,
	cursor?: string,
): Promise<NewsAndGuidesListPagePaginatedData> {
	const variables = { cursor: cursor ?? null, countryCode, locale, count };

	const { data } = guides
		? await fetch<GuidesListPaginatedDataQuery, GuidesListPaginatedDataQueryVariables>({
				query: GuidesListPaginatedData,
				variables,
				rejectOnError: true,
			})
		: await fetch<NewsListPaginatedDataQuery, NewsListPaginatedDataQueryVariables>({
				query: NewsListPaginatedData,
				variables,
				rejectOnError: true,
			});

	if (!data?.connection) throw new NotFoundError();

	return {
		items: data.connection.nodes,
		cursor: data.connection.pageInfo.endCursor,
		hasNextPage: data.connection.pageInfo.hasNextPage,
	};
}

export async function getFirstPageData(utilityContext: UtilityContext, args: Args) {
	// 1 main + 6 basic + 1 expanded = 8 cards
	return getData(utilityContext, args, 8);
}

export async function getNextPageData(utilityContext: UtilityContext, cursor: string, args: Args) {
	// 9 basic cards
	return getData(utilityContext, args, 9, cursor);
}
