import { useI18n } from '@change/core/react/i18n';

import { useNewsAndGuidesListPageData } from 'src/app/pages/newsAndGuides/list/pageContext';
import type { NewsAndGuidesListItem } from 'src/app/pages/newsAndGuides/list/pageContext';

type Result = ModelHookResult<{
	targetUrl: string;
	categoryName: string;
	smallPhotoUrl: string | undefined;
	photoUrl: string | undefined;
}>;

export function useItemCard(item: NewsAndGuidesListItem): Result {
	const {
		data: { countryCode },
	} = useNewsAndGuidesListPageData();
	const { translate } = useI18n();
	const targetUrl = `/l/${encodeURIComponent(countryCode)}/${encodeURIComponent(item.slug)}`;
	const categoryName =
		item.__typename === 'Guide' ? translate('fe.components.footer.guides') : item.mainCategory?.name || '';

	return {
		data: {
			targetUrl,
			categoryName,
			smallPhotoUrl: item.media?.sizes.small?.url || undefined,
			photoUrl: item.media?.sizes.medium?.url || undefined,
		},
	};
}
