import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';
import { Loader } from '@change/design-system/components/progressiveDisclosure';

import type { NewsAndGuidesListPagePrefetchedDataProps } from 'src/app/pages/newsAndGuides/list/pageContext';
import {
	NewsAndGuidesListPageContextProvider,
	prefetchPageContext,
} from 'src/app/pages/newsAndGuides/list/pageContext';
import { useQueryParamValue } from 'src/app/shared/hooks/location';

import { Layout } from '../layout';

import { NewsAndGuidesListErrorHandler } from './ErrorHandler';

export const NewsAndGuidesList = withPrefetchedData(
	function NewsAndGuidesList({ prefetchedData }: NewsAndGuidesListPagePrefetchedDataProps): JSX.Element {
		const { countryCode } = useKnownAvailableParams<{ countryCode: string }>();
		const guides = useQueryParamValue('category') === 'guides';

		return (
			<NewsAndGuidesListPageContextProvider
				countryCode={countryCode}
				guides={guides}
				prefetchedData={prefetchedData}
				errorHandler={NewsAndGuidesListErrorHandler}
				loadingFallback={<Loader size="m" my={256} mx="auto" />}
			>
				<Layout />
			</NewsAndGuidesListPageContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			prefetchPageContext(context.params.countryCode!, context.query.category === 'guides', context),
	},
);
