import type { ComponentProps, JSX, ReactElement } from 'react';

import { Translate } from '@change/core/react/i18n';
import { TabLink, TabLinks } from '@change/design-system/components/navigation';
import { Container } from '@change/design-system/layout';

import { usePageHeading } from './hooks/usePageHeading';

export function PageHeading(): JSX.Element {
	const {
		data: { showGuidesLink, urlBase, guides },
	} = usePageHeading();

	return (
		<Container variant="small" mt={8}>
			<TabLinks selected={guides ? 'guides' : 'news'}>
				{/* using forceMode=external to avoid loading indicator when switching tabs
				this would b unnecessary if those pages had different paths */}
				<TabLink name="news" to={urlBase} forceMode="external">
					<Translate value="fe.components.news_header.news" />
				</TabLink>
				{/* FIXME update types of Tabs to allow for conditional tab links */}
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(showGuidesLink ? (
						<TabLink name="guides" to={`${urlBase}?category=guides`} forceMode="external">
							<Translate value="fe.components.footer.guides" />
						</TabLink>
					) : null) as unknown as ReactElement<ComponentProps<typeof TabLink>>
				}
			</TabLinks>
		</Container>
	);
}
