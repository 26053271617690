import type { ForwardedRef } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Translate } from '@change/core/react/i18n';
import { Link } from '@change/design-system/components/actions';
import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { NewsAndGuidesListItem } from 'src/app/pages/newsAndGuides/list/pageContext';
import { useItemCard } from 'src/app/pages/newsAndGuides/list/shared/hooks';

type Props = {
	item: NewsAndGuidesListItem;
};

export function FeaturedCardInner({ item }: Props, ref: ForwardedRef<HTMLDivElement>) {
	const {
		data: { categoryName, targetUrl, photoUrl },
	} = useItemCard(item);

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				height: ['auto', 310, 360],
			}}
			pt={[0, 24, 40]}
			ref={ref}
		>
			<Box
				sx={{
					display: 'block',
					position: ['initial', 'absolute'],
					top: 0,
					right: 0,
					width: ['100%', '65%', '60%'],
					height: [240, '100%'],
					backgroundPosition: '50%',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
				// using style here is done on purpose for optimization purposes
				// eslint-disable-next-line react/forbid-component-props
				style={{
					// TODO use a default image unrelated to petitions?
					backgroundImage: `url("${photoUrl || 'https://static.change.org/images/default-petition.gif'}")`,
				}}
				role="presentation"
			/>
			<Box
				sx={{
					position: ['initial', 'relative'],
					width: ['100%', '66%'],
					backgroundColor: 'white',
				}}
				py={24}
				pr={[8, 30]}
				pl={[8, 0]}
			>
				<Text as="div" color="neutral-grey700" size="caption" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
					{categoryName}
				</Text>
				<Text as="div" size="large" breakWord sx={{ flex: 1, fontWeight: 'bold', fontSize: 20 }} my={8}>
					{item.title}
				</Text>
				<Html as="div" html={item.excerpt} breakWord lineClamp={[null, 3]} />
				<Link to={targetUrl} mt={8}>
					<Translate value="fe.pages.news_index.read_more" />
				</Link>
			</Box>
		</Box>
	);
}

export const FeaturedCard = forwardRef(FeaturedCardInner);
