import type { UtilityContext } from '@change/core/react/utilityContext';

import type { NewsAndGuidesListDataQuery, NewsAndGuidesListDataQueryVariables } from './api.graphql';
import { NewsAndGuidesListData } from './api.graphql';
import { NewsAndGuidesListPageDataError } from './types';

export type NewsAndGuidesListPageData = NewsAndGuidesListDataQuery & {
	countryCode: string;
	guides: boolean;
};

export async function getNewsAndGuidesListPageData(
	{ gql: { fetch } }: UtilityContext,
	{ countryCode, locale, guides }: { countryCode: string; locale: string; guides: boolean },
): Promise<NewsAndGuidesListPageData> {
	const { data } = await fetch<NewsAndGuidesListDataQuery, NewsAndGuidesListDataQueryVariables>({
		query: NewsAndGuidesListData,
		variables: {
			countryCode,
			locale,
		},
	});

	if (!data?.hasGuides && guides) {
		throw new NewsAndGuidesListPageDataError('news_and_guides_list_page_data_no_guides');
	}

	return { hasGuides: !!data?.hasGuides, countryCode, guides };
}
