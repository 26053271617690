import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useI18n } from '@change/core/react/i18n';

export function PageMeta(): JSX.Element {
	const { translate } = useI18n();

	return (
		<Helmet>
			{/* FIXME shouldn't those be different for guides? */}
			<title>{translate('fe.pages.news_index.title')}</title>
			<meta property="description" content={translate('fe.pages.news_index.description')} />
		</Helmet>
	);
}
