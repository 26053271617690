import { createPrefetchableAsyncDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getNewsAndGuidesListPageData } from './api';
import type { NewsAndGuidesListPageDataErrorCode } from './types';

export const {
	Context: NewsAndGuidesListPageDataContext,
	Provider: NewsAndGuidesListPageDataContextProvider,
	useContext: useNewsAndGuidesListPageData,
	useAsyncData: useNewsAndGuidesListPageDataFromApi,
	prefetchAsyncData: prefetchNewsAndGuidesListPageDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'NewsAndGuidesListPageDataContext',
	dataProperty: 'pageData',
	getData: getNewsAndGuidesListPageData,
	hookDeps: ({ countryCode, locale, guides }) => [countryCode, locale, guides],
	getUniqueId: ({ countryCode, locale, guides }) => [countryCode, locale, guides].join('|'),
	errorHandler: pageDataErrorHandler<NewsAndGuidesListPageDataErrorCode>(),
});
